.joins{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;

}
.left-j{
    font-size: 3rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    border-radius: 20%;
    border:2px solid var(--orange);
    width: 10.5rem;
    position: absolute;
    margin: -10px 0;
}
.right-j{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.from-container{
    display: flex;
    gap: 3rem;
    background-color: grey;
    padding: 1rem 2rem;
}
.from-container>input{
    background-color: transparent; 
    border: none;
    outline: none;
    color: var(--Lightgrayz);
}
::placeholder{
    color: var(--Lightgray);
}
.btn-j{
    color: white;
    background-color: orange;
}








@media (max-width: 768px) {


.joins{
    flex-direction: column;
    gap: 1rem;
}
.left-j{
    flex-direction: column;
    font-size: xx-large; 
}
.right-j{
    padding-top: 5rem;
    flex-direction: column;
}




}