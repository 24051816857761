.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
 
.right-h{
    flex: 1;
    background-color: var(--orange);
    position: relative;
}
 .the-best-add{
    background-color: grey;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    margin-top: 4rem;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
 }
 .the-best-add>span{
    z-index: 2;
 }
 .the-best-add>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
 }
 .hero-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
 }

 
.hero-text>div:nth-of-type(3)
{
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.figures{
  display: flex;
  gap: 2rem;
}
.figures>div{
  display: flex;
  flex-direction: column;

}
.figures>div>span:nth-of-type(1){
  color: white;
  font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
  color: var(--gray);
  text-transform: none;
  text-transform: uppercase;
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2){
    color: white;
    background-color:transparent;
    border: 2px solid var(--orange);
    width: 8rem;
}

.right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;

}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items:start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color:white;
    font-size: 1.5rem;
}
.hero_img{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}
.hero_img_back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: 13rem;
    position: absolute;
    top: 33rem;
    right: 28rem;
 
}
.calories>img{
    width: 3rem;

}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories > div>:nth-child(1){
    color: var(--gray);
}

.calories > div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}




/* ************************ */




@media (max-width: 768px) {

    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .the-best-add{
        font-size: small;
        align-self: center;
        margin-top: 0;
        transform: scale(.8);
    }
    .hero-text{
        justify-content: center;
        align-items: center;
        font-size: xx-large;
    }
    .hero-text>div:nth-child(3){
        font-weight: 200;
        font-size: small;
        letter-spacing: 1px ; 
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-child(1){
        font-size: large;
    }
    .figures>div>span:nth-child(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        top: 2rem;
        left: 1rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
        font-size: 15px;
    }
    .calories>img{
    width: 2rem;
    }
    .calories>div>:nth-child(2){
        font-size: 1rem;
        color: white;
    }
    .hero_img{
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-self: center;
    }
    .hero_img_back{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }



  }