.footer-container{
    position: relative;  
    text-align: center;
    gap: 4rem;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    padding: 1rem 2rem; 
    justify-content: center;
    align-items: center;
    gap: 4rem;
    flex-direction: column;
    height: 20rem;
}
 .social-links{
    display: flex;
    gap: 4rem;
 }
 .social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
 }
 
 .logo-f>img{
    width: 10rem;
 }
 .f-blur-1{
    height: 18rem;
    width: 12rem;
    right: 15%;
    bottom: 0;
    filter: blur(200px);
    background-color: rgb(243, 30, 30);
 }
 .f-blur-2{
    height: 18rem;
    width: 12rem;
    left: 15%;
    bottom: 0;
    filter: blur(200px);
    background-color:orange;
 }