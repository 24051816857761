.testimonials{
    display: flex;
    padding: 0 2rem;
    gap: 1rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 2rem;
    color: white;
    flex: 1;
}

.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2),.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
    gap: 1rem;
}

.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    height: 20rem;
    width: 17rem;
    right: 8rem;
    top: 2rem;
    object-fit: cover;

}
.right-t>:nth-child(1){
    position: absolute;
    height: 20rem;
    width: 17rem;
    border: 2px solid orange;
    top: 0.9rem;
    right: 9rem;
    color: transparent;
}
.right-t>:nth-child(2){
    position: absolute;
    height: 19rem;
    width: 17rem;
    background: var(--planCard);
    right: 7rem;
    top: 4rem; 
}
.arrows{
    position: absolute;
    bottom: 1rem;
    left: 3rem;
    display: flex;
    gap: 1rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}







@media (max-width: 768px) {

    .testimonials{
        flex-direction: column;
    }
    .left-t{
        flex-direction: column;
    }
    .left-t>:nth-child(2),.left-t>:nth-child(3){
        font-size: xx-large;
    }
    .right-t>div{
        position: relative;
        display: none;
    }
    .right-t>img{
        position: relative;
        top: 0;
        right: 0;
        align-self: center;
    }
    
    .right-t>:last-child{
        display: block;
        bottom: -1rem;
        left: 6rem;
    }




}